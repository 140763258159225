import React from 'react';

export const ToolTip = ({
  isActive,
  children,
  unclickable = false,
  isCenter = false,
}) => {
  return (
    <div
      className={`tool-tip ${isActive ? 'is-active' : ''} ${
        isCenter ? 'is-center' : ''
      } ${unclickable ? 'is-unclickable' : ''}`}
    >
      {children}
    </div>
  );
};
