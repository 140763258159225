import React from 'react';
import {
  FaTwitter,
  FaFacebookF,
  FaPinterest,
  FaEnvelope,
} from 'react-icons/fa';

const ShareIcons = ({
  url,
  title,
  facebook = true,
  twitter = true,
  pinterest = true,
  email = true,
}) => (
  <ul className="share-items">
    {facebook ? (
      <li>
        <a
          href={`#`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank', 'width=400,height=350');
          }}
        >
          <FaFacebookF />
        </a>
      </li>
    ) : null}
    {twitter ? (
      <li>
        <a
          href={`#`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.open(`https://twitter.com/intent/tweet?url=${url}`, '_blank', 'width=400,height=450');
          }}
        >
          <FaTwitter />
        </a>
      </li>
    ) : null}
    {pinterest ? (
      <li>
        <a
          href={`#`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.open(`https://pinterest.com/pin/create/button/?url=${url}`, '_blank', 'width=400,height=350');
          }}
        >
          <FaPinterest />
        </a>
      </li>
    ) : null}
    {email ? (
      <li>
        <a
          href={`mailto:info@example.com?${
            title ? `&subject=${title}` : ''
          }&body=${url}`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FaEnvelope />
        </a>
      </li>
    ) : null}
  </ul>
);

export default ShareIcons;
