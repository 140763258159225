import { baseUrl } from './drupal';
import { doFetch } from './register'
import axios from 'axios';

const submissionURL = `${baseUrl}/webform_rest/submit`;
const fileUploadURL = `${baseUrl}/upload/`;
//const fileUploadURL = `http://localhost:3000/upload/`;
//
/**
 * webformSubmission
 * ---
 * Register a new user in Drupal
 * 
 * Provide user registration fields
 * @param formID - the form ID
 * @param fields - the form fields to submit
 * @returns {Promise<void>}
 * Returns a promise that resolves to JSON response from Drupal.
 */
 export const webformSubmission = async (formID, fields) => {
    
    // set webform ID
    let submissionBody = {...fields}
    submissionBody.webform_id = formID;

    const response = doFetch({
        url: submissionURL,
        format: 'json',
        headers: {
            'Content-Type': 'application/json',
        },
        body: submissionBody
    })
    
    // check the reponse and return
    return response.then((value) => {
        // valid response
        if (value.sid) {
            return {error: false, data: value};
        }
        // invalid response
        if (value.message) {
            return {error: true, msg: value};
        }
        // fallback
        return {error: true, msg: 'Something went wrong on our end while uploading this image. Sorry about that. Please refresh and try again.'}
    });
  };


  export const webformFileUpload = async (contents) => {
    
    // using a proxy for the request for development
    // this will need to be refactored to function properly
    const response = await axios.post(fileUploadURL, contents);

    if (response.status !== 200) {
        console.log(response.status);
    }

    const value = response.data;

    if (value.data && value.data.attributes && value.data.attributes.drupal_internal__fid) {
        return {error: false, data: value.data.attributes.drupal_internal__fid};
    }
    // invalid response
    if (value.message) {
        return {error: true, msg: value};
    }
    // fallback
    return {error: true, msg: 'Something went wrong on our end, sorry about that. Please refresh the page and try again.'}

    /* const response = doFetch({
        url: fileUploadURL,
        format: 'json',
        headers: {
            //'Content-Type': 'multipart/form-data',
            //'Content-Type': 'application/octet-stream',
            //'Content-Disposition': `file; filename="${name}"`,
        },
        body: contents
    })


    if (!response.ok) {
        console.error(response.status);
    }

    response.then(console.log);

    // check the reponse and return
    return response.then((value) => {
        // valid response
        if (value.data && value.data.attributes && value.data.attributes.drupal_internal__fid) {
            return {error: false, data: value.data.attributes.drupal_internal__fid};
        }
        // invalid response
        if (value.message) {
            return {error: true, msg: value};
        }
        // fallback
        return {error: true, msg: 'Something went wrong on our end, sorry about that. Please refresh the page and try again.'}
    }); */
};



