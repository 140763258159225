import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { webformSubmission, webformFileUpload } from '../../util/auth/submit'

export default function Webform ({name, dynamic}) {

    const [ submit, setSubmit ] = useState(false);
    const [ formFields, setFormFields ] = useState({});
    const [ error, setError ] = useState({});
    const [ success, setSuccess ] = useState(false);
    const [ form, setForm ] = useState(null);

    const webFormData = useStaticQuery(graphql`
    query WebformQuery {
        allWebformWebform {
            edges {
                node {
                    elements {
                      name
                      type
                      attributes {
                          name
                          value
                      }
                      # removed to prevent fetch failure
                      # options {
                      #   label
                      #   value
                      # }
                    }
                    archive
                    title
                    drupal_id
                    drupal_internal__id
                }
            }
        }
      }
    `)

    const setFormFieldValue = (value, field) => {
      let newFields = {...formFields};
      newFields[field] = value;
      setFormFields(newFields);
    }

    const handleFormSubmission = (e) => {
      e.preventDefault();
      if (!submit) {
          setSubmit(true);
      }
    }

    // handle form submission
    useEffect(() => {

        async function fetchSubmit() {

            const res = await webformSubmission(form.drupal_internal__id, formFields);

            // there's an error, display it
            if (res.error) {
                setError(
                  extractHumanFriendlyError(res.msg)
                );
                setSuccess(null);
            }
            // no error, do stuff
            else {
                setSuccess(true);
                setError({});
                setFormFields({});
            }

            // we've finished submitting
            // set submit to false
            setSubmit(false);
        }

        // validate form fields first
        if (validateFormFields()) {

          if (submit) {
              // if submit, fetch
              fetchSubmit();
          }

        }

    }, [submit]);

    // take the response from drupal and make it more...
    // useful.
    const extractHumanFriendlyError = (response) => {

      let friendlyErrors = {};
      if (response.message && response.message.includes('validation errors')) {
        friendlyErrors = {...response.error}
      }

      // check if we found any identifiable errors
      // if not, return a general error that
      // we can use to pass info along to the
      // users
      if (Object.keys(friendlyErrors).length) {
          return {...friendlyErrors, general: "Please correct the following errors"};
      } else {
          // return a general error
          return {
              general: 'There was an error submitting this form'
          }
      }
  }

    useEffect(() => {
      // webforms returned
      if (webFormData.allWebformWebform.edges.length) {
        // get the form by the form name
        const f = getFormByName({name, forms: webFormData.allWebformWebform.edges});
        setForm(f);
      }

    }, []);

    const validateFormFields = () => {

      if (!submit) return true;

      let errors = {}

      form.elements.forEach((element) => {
        const settings = getFormAttributes(element);
        if (settings.required && (!formFields[element.name] || formFields[element.name].length === 0)) {
           errors[element.name] = 'Required';
           if (settings.required_error) {
              errors[element.name] = settings.required_error;
           }
        }
      })

      if (Object.keys(errors).length) {
          setError(errors);
          setSubmit(false);
          return false;
      }
      return true;
    }

    // if no form was found matching that name
    if (!form) return <WebformErrorLoading />;

    // no form elements found
    if (!form.elements.length) return <WebformErrorLoading />;


    if (success) {
      return (
        <div className="login-form-section">
          <h3 className="t-center t-mxlarge t-heading">{name}</h3>
          <div className="callout success">
              Your submission has been recieved!
          </div>
        </div>
      )
    }

    // at this point, we have a valid form loaded
    // lets build the form
    return (
      <div className="login-form-section">
        <h3 className="t-center t-mxlarge t-heading">{name}</h3>

        {error.general && (
            <div className="callout error">
                {error.general}
            </div>
        )}

      <form
          onSubmit={handleFormSubmission}
      >
          {form.elements.map((element, i) => {
            switch(element.type) {
                case 'email':
                  return (<Textfield
                    key={`input_${i}`}
                    onUpdate={setFormFieldValue}
                    value={formFields[element.name]}
                    error={error[element.name]}
                    element={element}
                    type="email"
                  />)
                break;
                case 'textarea':
                  return(<Textarea
                    key={`input_${i}`}
                    onUpdate={setFormFieldValue}
                    value={formFields[element.name]}
                    error={error[element.name]}
                    element={element}
                  />)
                break;
                case 'select':
                  return(<Selectfield
                    key={`input_${i}`}
                    onUpdate={setFormFieldValue}
                    value={formFields[element.name]}
                    error={error[element.name]}
                    element={element}
                  />)
                case 'webform_image_file':
                    return(<Filefield
                        key={`input_${i}`}
                        onUpdate={setFormFieldValue}
                        value={formFields[element.name]}
                        error={error[element.name]}
                        setError={setError}
                        element={element}
                      />)
                case 'webform_actions':
                  return (<Submit
                            key={`input_${i}`}
                            element={element}
                            submit={submit}
                          />);
                break;
                default:
                  return (<Textfield
                              key={`input_${i}`}
                              onUpdate={setFormFieldValue}
                              value={formFields[element.name]}
                              error={error[element.name]}
                              element={element}
                              dynamic={dynamic}
                          />)
                break;
            }
          })}
      </form>
      </div>
    );
}

const WebformErrorLoading = () => (
    <p>an Error occurred while loading this form</p>
)

const getFormByName = ({name, forms}) => {
    for (const i in forms) {
        let form = forms[i].node;
        if (form.title === name) {
            return form;
        }
    }
    return false;
}

const getFormAttributes = (element) => {
  let attributes = {}
  for (const i in element.attributes) {
      let attribute = element.attributes[i];
      attributes[attribute.name.replace("#", "")] = attribute.value;
  }
  return attributes;
}

const Textarea = ({element, value, onUpdate, error}) => {

  const settings = getFormAttributes(element);

  return (
    <div className="input-item">
      <label className="t-small" htmlFor={settings.title}>
          {settings.title}
      </label>
      <textarea
          value={value}
          autoComplete={settings.title}
          id={settings.title}
          name={settings.title}
          onChange={(e) => onUpdate(e.target.value, element.name)}
          placeholder={settings.placeholder || settings.title}
          data-error={error && (true)}
      />
      {error && (<span className="error" dangerouslySetInnerHTML={{__html: error}}></span>)}
  </div>
  )
}

const Selectfield = ({element, value, onUpdate, error}) => {

  const settings = getFormAttributes(element);

  return (
    <div className="input-item">
      <label className="t-small" htmlFor={settings.title}>
          {settings.title}
      </label>
      <select
          value={value}
          autoComplete={settings.title}
          id={settings.title}
          name={settings.title}
          onChange={(e) => onUpdate(e.target.value, element.name)}
          data-error={error && (true)}
      >
        <option value="">{settings.empty_option}</option>
        {element.options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
      {error && (<span className="error" dangerouslySetInnerHTML={{__html: error}}></span>)}
  </div>
  )
}

const Textfield = ({element, value, onUpdate, error, type = 'text', dynamic}) => {

  const settings = getFormAttributes(element);
  console.log('element attrs', element.attributes)

  let prepopulatedValue = value;
  let disabled = false;
  if (settings.dynamic_field) {
    prepopulatedValue = dynamic[settings.dynamic_field];
    if (value != prepopulatedValue) {
      onUpdate(prepopulatedValue, element.name);
    }
    disabled = true;
  }

  return (
    <div className="input-item">
      <label className="t-small" htmlFor={settings.title}>
          {settings.title}
      </label>
      <input
          value={prepopulatedValue}
          autoComplete={settings.title}
          disabled={disabled}
          id={settings.title}
          name={settings.title}
          onChange={(e) => onUpdate(e.target.value, element.name)}
          type={type}
          placeholder={settings.title}
          data-error={error && (true)}
      />
      {error && (<span className="error" dangerouslySetInnerHTML={{__html: error}}></span>)}
  </div>
  )
}

const Filefield = ({element, value, onUpdate, error, setError}) => {

  const [uploading, setUploading] = useState(false);
  const settings = getFormAttributes(element);

  return (
    <div className="input-item">
      <label className="t-small" htmlFor={settings.title}>
          {settings.title}
      </label>
      <input
          autoComplete={settings.title}
          id={settings.title}
          name={settings.title}
          onChange={ async (e) => {
            let file = e.target.files[0];
            const name = file.name;
            const data = new FormData();
            data.append('file', e.target.files[0]);
            data.append('name', name);
            const fileres = await webformFileUpload(data);

            if (fileres.error) {
              setError({
                general: fileres.msg
              });
            } else {
              onUpdate(fileres.data, element.name)
            }

          }}
          type="file"
          placeholder={settings.title}
          data-error={error && (true)}
      />
      {uploading && (<span>uploading attachment...</span>)}
      {error && (<span className="error" dangerouslySetInnerHTML={{__html: error}}></span>)}
  </div>
  )
}

const Submit = ({element, submit}) => {

  const settings = getFormAttributes(element);

  return (
    <div className="input-item">
      <input type="submit" data-loading={submit && (true)} value={submit ? 'Loading...' : settings.submit__label} />
    </div>
  )
}