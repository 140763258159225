import React, { useState, useEffect, useContext } from 'react';
import { FiHeart, FiShare, FiPlusCircle } from 'react-icons/fi';
import { AiOutlinePrinter } from 'react-icons/ai';
import { HiOutlineMail } from 'react-icons/hi';
import { FaRegCheckCircle, FaHeart } from 'react-icons/fa';
import { UserContext } from '../../context/user/context';
import { ToolTip } from './ToolTip';
import useAddArtist from '../../util/hooks/useAddArtist';
import useRemoveArtist from '../../util/hooks/useRemoveArtist';
import ShareIcons from './ShareIcons';
import useAddArtwork from '../../util/hooks/useAddArtwork';
import useRemoveArtwork from '../../util/hooks/useRemoveArtwork';
import Modal from './Modal';
/* import InquireArtwork from './InquireArtwork'; */
import Webform from '../global/Webform';
import LoginFlow from './LoginFlow';

const ActionButtons = ({ 
  drupal_id = '',
  showText = true,
  url,
  title,
  artist_title = '',
  location = `location - ${typeof window !== 'undefined' ? window.location.href : null}`,
  showToolTips = false,
  centerToolTips = false,
  setIsPrinting = () => {},
  showPrint = false,
}) => {
  const [saved, setSaved] = useState(false);
  const [saveReminder, setSaveReminder] = useState(false);
  const [showSaveToolTip, setShowSaveToolTip] = useState(false);
  const [shareTool, setShareTool] = useState(false);
  const [shareToolTip, setShareToolTip] = useState(false);
  const [printToolTip, setPrintToolTip] = useState(false);
  const [inquireToolTip, setInquireToolTip] = useState(false);
  const { state, userUpdateArtworkSave } = useContext(UserContext);
  const { user } = state;
  const [addStatus, setAddArtworkId] = useAddArtwork();
  const [removeStatus, setRemoveArtworkId] = useRemoveArtwork();
  const [enquireModal, setEnquireModal] = useState(false);
  // login state

  useEffect(() => {
    if (user.isLoggedIn && !saved) {
      const artworksSaved = user.field_artwork_saved.filter(
        (id) => id == drupal_id
      );

      setSaved(artworksSaved.length > 0);
    }
  }, [
    user.field_artwork_saved,
    user.isLoggedIn,
    saved,
    setSaved,
    userUpdateArtworkSave,
  ]);

  const handleSave = (e) => {
    e.preventDefault();

    if (user.isLoggedIn) {
      try {
        if (saved) {
          setRemoveArtworkId(drupal_id);
          userUpdateArtworkSave(drupal_id);
          setSaved(!saved);
        } else {
          setAddArtworkId(drupal_id);
          userUpdateArtworkSave(drupal_id);
          setSaved(!saved);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setSaveReminder(true);
      setTimeout(() => setSaveReminder(false), 3000);
    }

    setShowSaveToolTip(false);
  };

  const handlePrint = (e) => {
    e.preventDefault();
    setIsPrinting(true);
  };

  return (
    <ul className="piece-buttons">
      {drupal_id ? (
        <li>
          <button
            onClick={handleSave}
            onMouseEnter={() => {
              if (saveReminder) {
                setShowSaveToolTip(false);
              } else {
                setShowSaveToolTip(true);
              }
            }}
            onMouseLeave={() => setShowSaveToolTip(false)}
          >
            {saved ? <FaHeart /> : <FiHeart />}
            {showText ? <span>{saved ? 'Saved' : 'Save'}</span> : null}
            {showToolTips ? (
              <ToolTip
                isActive={showSaveToolTip}
                unclickable={true}
                isCenter={centerToolTips}
              >
                Save Artwork
              </ToolTip>
            ) : null}
            <ToolTip isActive={saveReminder} isCenter={centerToolTips}>
              <LoginFlow>Please <span style={{textDecoration: 'underline', padding: '0px 10px 0px 2px'}}>login/signup</span> to save</LoginFlow>
            </ToolTip>
          </button>
        </li>
      ) : null}

      <li>
        <button
          onClick={(e) => {
            e.preventDefault();
            setShareTool(!shareTool);
          }}
          onMouseEnter={() => {
            if (shareTool) {
              setShareToolTip(false);
            } else {
              setShareToolTip(true);
            }
          }}
          onMouseLeave={() => setShareToolTip(false)}
        >
          <FiShare />
          {showText ? <span>Share</span> : null}
          {showToolTips ? (
            <ToolTip
              isActive={shareToolTip}
              unclickable={true}
              isCenter={centerToolTips}
            >
              Share
            </ToolTip>
          ) : null}
          <ToolTip isActive={shareTool} isCenter={centerToolTips}>
            <ShareIcons
              url={url}
              title={`${artist_title ? `${artist_title} - ` : ''}${title}`}
            />
          </ToolTip>
        </button>
      </li>
      {showPrint ? (
        <li className="print">
          <button
            onClick={(e) => handlePrint(e)}
            onMouseEnter={() => setPrintToolTip(true)}
            onMouseLeave={() => setPrintToolTip(false)}
          >
            <AiOutlinePrinter />
            {showText ? <span>Print</span> : null}
            {showToolTips ? (
              <ToolTip
                isActive={printToolTip}
                unclickable={true}
                isCenter={centerToolTips}
              >
                Print
              </ToolTip>
            ) : null}
          </button>
        </li>
      ) : null}
      <li>
        <button
          onClick={() => setEnquireModal(true)}
          onMouseEnter={() => setInquireToolTip(true)}
          onMouseLeave={() => setInquireToolTip(false)}
        >
          <HiOutlineMail />
          {showText ? <span>Inquire</span> : null}
          {showToolTips ? (
            <ToolTip
              isActive={inquireToolTip}
              unclickable={true}
              isCenter={centerToolTips}
            >
              Inquire
            </ToolTip>
          ) : null}
        </button>
        <Modal
          id="enquire-wrapper"
          active={enquireModal}
          onClose={() => setEnquireModal(false)}
        >
          <Webform
            name="Inquire About This Artwork"
            dynamic={{
              artwork_populate_dynamic: `${
                artist_title ? `${artist_title} - ` : ''
              }${title}`,
              artwork_location_populate_dynamic: location
            }}
          />
        </Modal>
      </li>
    </ul>
  );
};

export default ActionButtons;

export const FollowBtn = ({
  drupal_id = '',
  followingLabel = 'Following',
  followLabel = 'Follow',
}) => {
  const [follow, setFollow] = useState(false);
  const [toolTip, setToolTip] = useState(false);
  const { state, userUpdateArtistFollow } = useContext(UserContext);
  const { user } = state;
  const [addStatus, setAddArtistId] = useAddArtist();
  const [removeStatus, setRemoveArtistId] = useRemoveArtist();

  useEffect(() => {
    if (user.isLoggedIn) {
      const artistsFollowing = user.field_artist_of_interest.filter(
        (id) => id == drupal_id
      );

      setFollow(artistsFollowing.length > 0);
    }
  }, [
    user.field_artist_of_interest,
    user.isLoggedIn,
    setFollow,
    follow,
    userUpdateArtistFollow,
  ]);

  const handleClick = async () => {
    if (user.isLoggedIn) {
      try {
        if (follow) {
          setRemoveArtistId(drupal_id);
          userUpdateArtistFollow(drupal_id);
          setFollow(!follow);
        } else {
          setAddArtistId(drupal_id);
          userUpdateArtistFollow(drupal_id);
          setFollow(!follow);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setToolTip(true);
      setTimeout(() => setToolTip(false), 3000);
    }
  };

  if (!drupal_id) return null;

  return (
      <button className="followBtn" onClick={() => handleClick()}>
        {follow ? (
          <React.Fragment>
            <FaRegCheckCircle className="mr-10" /> {followingLabel}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FiPlusCircle className="mr-10" /> {followLabel}
          </React.Fragment>
        )}
        <ToolTip isActive={toolTip}>
          <LoginFlow>Please login/signup to save</LoginFlow>
        </ToolTip>
      </button>
  );
};
