import { useState, useEffect } from 'react';
import { baseUrl } from '../auth/drupal';

/**
 * Hook for fetching artists by their ids. Will also fetch their images.
 * Will filter out failed fetches.
 * @param {string} artist_id
 * @return { {status: Boolean, message: String} } Status of the response and message
 */
const useAddArtist = () => {
  const [addArtistId, setAddArtistId] = useState('');
  const [addStatus, setStatus] = useState(false);

  useEffect(() => {
    const addArtistById = async () => {
      try {
        const res = await fetch(
          `${baseUrl}/jsonapi/add-to-favorite/${addArtistId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          }
        );

        const data = await res.json();

        if (data.mesage === 'success') {
          setStatus(true);
        } else {
          setStatus(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (addArtistId !== '') {
      addArtistById();
    }
  }, [addArtistId]);

  return [addStatus, setAddArtistId];
};

export default useAddArtist;
